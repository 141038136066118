<ng-container
  *ngIf="
    menu && menu.displayName && menu.displayName !== 'root';
    else rootMenuTemplate
  "
>
  <ng-container
    *ngIf="
      menu.children &&
      (!menu.displayType || (menu.displayType && menu.displayType === 'menu'))
    "
  >
    <mat-menu #nuwaStylerMenu="matMenu">
      <ng-template matMenuContent let-item="item">
        <nuwa-menu
          *ngFor="let child of item.children"
          [menu]="child"
          (menuAction)="menuAction.emit($event)"
          [context]="context"
        ></nuwa-menu>
      </ng-template>
    </mat-menu>
    <button
      [matMenuTriggerFor]="nuwaStylerMenu"
      [matMenuTriggerData]="{ item: menu }"
      (menuOpened)="openedMenu($event)"
      mat-menu-item
    >
      <div>
        {{
          menu.displayName && menu.displayName !== 'root'
            ? menu.displayName
            : ''
        }}
      </div>
    </button>
  </ng-container>
  <ng-container
    *ngIf="menu.children && menu.displayType && menu.displayType === 'list'"
  >
    <div>
      {{
        menu.displayName && menu.displayName !== 'root' ? menu.displayName : ''
      }}
    </div>
    <nuwa-menu
      *ngFor="let child of menu.children"
      [menu]="child"
      (menuAction)="menuAction.emit($event)"
      [context]="context"
    ></nuwa-menu>
  </ng-container>
  <ng-container *ngIf="menu.emits">
    <button
      mat-menu-item
      (click)="emit($event)"
      [style.backgroundColor]="
        menu.displayType === 'color' ? menu.displayName : '#fff'
      "
      [style.fontFamily]="menu.displayType === 'font' ? menu.displayName : ''"
      [style.color]="menu.displayType === 'color' ? 'transparent' : '#000'"
    >
      <div>
        {{ menu.displayName }}
      </div>
    </button>
  </ng-container>
</ng-container>
<ng-template #rootMenuTemplate>
  <ng-container  *ngIf="menu && (!menu.displayType || menu.displayType && menu.displayType === 'menu')">
  <mat-menu
    #nuwaStylerRoot="matMenu"
    [xPosition]="xPosition"
    [yPosition]="yPosition"
  >
    <ng-template matMenuContent>
      <nuwa-menu
        (menuAction)="menuAction.emit($event)"
        *ngFor="let child of children$ | async"
        [menu]="child"
      
      ></nuwa-menu>
    </ng-template>
  </mat-menu>
  <button
    [matMenuTriggerFor]="nuwaStylerRoot"
    mat-menu-item
    class="root-trigger"
    (menuOpened)="openedMenu($event)"
    (menuClosed)="closedMenu($event)"
  >
    <div></div>
  </button>
  </ng-container>
  <ng-container  *ngIf="menu && ( menu.displayType && menu.displayType === 'list')">
    LIST
    <nuwa-menu
    (menuAction)="menuAction.emit($event)"
    *ngFor="let child of children$ | async"
    [menu]="child"
    
  ></nuwa-menu>
  </ng-container>
</ng-template>

<ng-content></ng-content>
