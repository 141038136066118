import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { FormConfig } from '../nuwaEditor/editor.interface';
import { FormControl } from '@angular/forms';
import { Observable, BehaviorSubject } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { take, tap, startWith, map } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatDialogRef } from '@angular/material/dialog';
import { AppDataGridConfig, AppDataGridEvent } from '../data-grid/data-grid.component';

@Component({
  selector: 'app-selector',
  templateUrl: './selector.component.html',
  styleUrls: ['./selector.component.scss']
})
export class NuwaSelectorComponent {
  selected$ = new BehaviorSubject<any>(null);

  dataSource$: Observable<any>;
  gridConfig: AppDataGridConfig;
  idKey: string;
  constructor(public bottomSheet: MatBottomSheetRef<any>, @Inject(MAT_BOTTOM_SHEET_DATA) public data: any) {
    if (data.source$ !== null && data.gridConfig !== null && data.idKey) {
      this.dataSource$ = data.source$;
      this.gridConfig = data.gridConfig;
      this.idKey = data.idKey;
    } else {
      this.bottomSheet.dismiss(null);
    }
  }
  select(event: AppDataGridEvent): void {
    if (event.control === 'select') {
      this.bottomSheet.dismiss(event.record);
    }
  }
}

//   @Input() selectorConfig: EditorConfig;
//   control = new FormControl();
//   @Output() command: any;
//   options: any[];
//   filteredOptions: Observable<any[]>;
//   constructor(private afs: AngularFirestore) {}

//   ngOnInit() {
//     if (this.selectorConfig.collection && this.selectorConfig.displayFn) {
//       const collection = this.selectorConfig.collection;
//       this.afs
//         .collection(collection)
//         .valueChanges()
//         .pipe(
//           take(1),
//           tap(items => {
//             if (items && Array.isArray(items) && items.length > 0) {
//               this.options = items;
//             }
//           })
//         )
//         .subscribe();
//     }
//     this.filteredOptions = this.control.valueChanges.pipe(
//       startWith(''),
//       map(value => (typeof value === 'string' ? value : this.displayFn(value))),
//       map(display => (display ? this._filter(display) : this.options.slice()))
//     );
//   }
//   optionSelected(event: MatAutocompleteSelectedEvent) {
//       this.command.emit({ ...this.selectorConfig, selected: event.option });
//   }
//   displayFn(option) {
//     return this.selectorConfig && this.selectorConfig.displayFn ? this.selectorConfig.displayFn(option) : option;
//   }
//   private _filter(value: string): any[] {
//     const filterValue = value.toLowerCase();

//     return this.options && Array.isArray(this.options) && this.options.length > 0
//       ? this.options.filter(option => {
//           const displayValue = this.displayFn(option);
//           return typeof displayValue === 'string' ? displayValue.toLowerCase().includes(filterValue) : false;
//         })
//       : [];
//   }
// }
