  <div class="label">
    {{controlConfig.label}}
  </div>
  <div class="group">
    <ng-container *ngFor="let item of controlConfig.options">
      <div class="item" [class.selected]="(item && item.value !== undefined ? item.value : item) === control.value"
        [ngClass]="controlConfig.inputType && controlConfig.inputType === 'icons' ? 'icon' : 'text'"
        (click)="setValue(item)">
        <div>{{ item && item.value !== undefined && item.label ? item.label : item }}</div>
      </div>
    </ng-container>
  </div>
