import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pluck'
})
export class PluckPipe implements PipeTransform {
  transform(value: any, field: string): any {
    return Array.isArray(value) ? value.map(o => (o && o[field] ? o[field] : '')) : [];
  }
}
