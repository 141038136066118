import { Component, OnInit, Input } from '@angular/core';
import { tap, finalize, delay } from 'rxjs/operators';
import { FormGroup, FormControl } from '@angular/forms';
import { FormConfig } from '../editor.interface';
import {
  AngularFireStorage,
  AngularFireUploadTask,
} from '@angular/fire/storage';
import { guid } from 'src/app/functions/guid';
@Component({
  selector: 'app-image',
  template: ` 
    <ng-container *ngIf="control?.value && control?.value?.trim() !== ''">
      <img  [src]="control.value | safesourceurl" />
    </ng-container>
    <div class="ico">
    <mat-icon>add_a_photo</mat-icon
    ></div>
      <input
        class="trigger-upload"
        type="file"
        (change)="uploadImage($event)"
      />
  `, styles: [
    `
      :host{
        position:relative;
        grid-template-columns : 1fr;
        grid-template-rows : 1fr;
        display: grid;
        background-color: transparent;
      }
      
      img {
        grid-area: 1/1/2/2;
        width:100%;
        min-height: 50px;
      }  
      .ico {
        grid-area: 1/1/2/2;
display: grid;
opacity:0.2;
width: 100%;
height:100%;
color: #0064af;
place-content: center;
border:1px solid #777;
}

      input.trigger-upload {
        grid-area: 1/1/2/2;
        background: rgba(0, 0, 0, 0.0001);
        opacity: 0.00001;
        place-self: center;
        height: 100%;
        width: 100%;
        z-index: 2;
      }
      :host:hover .ico {
        opacity: 1;
      }
      :host:hover img {

        opacity: .5;
      }
    `,
  ],
})
export class ImageComponent implements OnInit {
  @Input() controlConfig: FormConfig;
  @Input() control: FormControl;
  task: AngularFireUploadTask;
  percentage: any;
  downloadURL: any;
  constructor(private storage: AngularFireStorage) { }
  ngOnInit() { }
  uploadImage(ev) {
    if (ev && ev.target && ev.target.files && ev.target.files[0]) {
      const file = ev.target.files[0];
      const path = `imageUploads/${guid(16)}_${file.name}`;
      const ref = this.storage.ref(path);
      const task = this.storage.upload(path, file);
      task
        .snapshotChanges()
        .pipe(
          finalize(async () => {
            const src = await ref.getDownloadURL().toPromise();

            if (src) {
              this.control.setValue(src);
            }
          })
        )
        .subscribe();
    }
  }
}
