import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'locationtext'
})
export class LocationTextPipe implements PipeTransform {
  transform(value: any, data?: any): any {
    if (typeof value === 'string') {
      if (
        data &&
        data.location &&
        data.location.dash &&
        typeof data.location.dash === 'string' &&
        data.location.dash !== 'restaurants-near-me'
      ) {
        return value
          .replace(/##location##/gi, 'in ' + data.location.name)
          .replace(/##area##/gi, data.location.area ? data.location.area : '');
      } else if (
        data &&
        data.location &&
        data.location.dash &&
        typeof data.location.dash === 'string' &&
        data.location.dash === 'restaurants-near-me'
      ) {
        return value
          .replace(/##location##/gi, 'Near Me')
          .replace(/##area##/gi, '');
      } else {
        return value.replace(/##location##/gi, '').replace(/##area##/gi, '');
      }
    }
    return value;
  }
}
