<ng-container *ngIf="controlConfig && controlConfig.type">
  <app-display *ngIf="controlConfig.type === 'display'" [control]="control" [controlConfig]="controlConfig"></app-display>
  <app-anchor *ngIf="controlConfig.type === 'anchor'" [control]="control" [controlConfig]="controlConfig"></app-anchor>
  <app-input *ngIf="controlConfig.type === 'input'" [control]="control" [controlConfig]="controlConfig"></app-input>
  <app-image *ngIf="controlConfig.type === 'image'" [control]="control" [controlConfig]="controlConfig"></app-image>
  <app-textarea *ngIf="controlConfig.type === 'textarea'" [control]="control" [controlConfig]="controlConfig"></app-textarea>
  <app-select *ngIf="controlConfig.type === 'select'" [control]="control" [controlConfig]="controlConfig"></app-select>
  <!-- <app-multi-select *ngIf="controlConfig.type === 'multiselect'" [control]="control" [controlConfig]="controlConfig"></app-multi-select> -->
  <app-toggle *ngIf="controlConfig.type === 'toggle'" [control]="control" [controlConfig]="controlConfig"></app-toggle>
  <!-- <app-chips *ngIf="controlConfig.type === 'chips'" [control]="control" [controlConfig]="controlConfig"></app-chips> -->
  <app-date *ngIf="controlConfig.type === 'date'" [control]="control" [controlConfig]="controlConfig"></app-date>
  <!-- <app-radiobutton *ngIf="controlConfig.type === 'radiobutton'" [control]="control" [controlConfig]="controlConfig"></app-radiobutton> -->
  <app-checkbox *ngIf="controlConfig.type === 'checkbox'" [control]="control" [controlConfig]="controlConfig"></app-checkbox>
  <app-slide-toggle *ngIf="controlConfig.type === 'slidetoggle'" [control]="control" [controlConfig]="controlConfig"></app-slide-toggle>
  <!-- <app-html *ngIf="controlConfig.type === 'html'" [control]="control" [controlConfig]="controlConfig"></app-html> -->
  <app-menu *ngIf="controlConfig.type === 'menu'" [control]="control" [controlConfig]="controlConfig"></app-menu>
</ng-container>
