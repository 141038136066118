<mat-form-field class="editor-full-width" appearance="standard">
  <mat-label style="color:#0064af">{{ controlConfig?.label }}</mat-label>
  <mat-select
    [formControl]="control"
    [style.fontFamily]="controlConfig.inputType && controlConfig.inputType === 'icons' ? 'Material Icons' : 'unset'"
  >
    <mat-option
      [style.fontFamily]="controlConfig.inputType && controlConfig.inputType === 'icons' ? 'Material Icons' : 'unset'"
      *ngFor="let item of controlConfig.options"
      [value]="item && item.value !== undefined && item.label ? item.value : item"
    >
      {{ item && item.value !== undefined && item.label ? item.label : item }}
    </mat-option>
  </mat-select>
  <mat-hint>{{controlConfig?.hint}}</mat-hint>
</mat-form-field>
