<ng-container *ngIf="(ready | async) !=='loading'">
  <button mat-icon-button class="exit-form-button" (click)="emitClose()"><mat-icon>arrow_back</mat-icon></button>
  <form class="dynamic-form" [formGroup]="formGroup">
    <app-group
      [groupConfig]="formConfig"
      [group]="formGroup"
      (action)="formAction($event)"
      (command)="formCommand($event)"
      [ngClass]="formConfig?.ngClass"
      [ngStyle]="formConfig?.ngStyle"
    >
    </app-group>
  </form>
  <!-- <div class="save-action" (click)="emitValue()"><div>UPDATE</div></div> -->
  <!-- div (click)="onSubmit()">save</!-->
</ng-container>
