import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MatMenuTrigger } from '@angular/material/menu';

@Injectable({
  providedIn: 'root'
})
export class NuwaMenuService {
  private triggers: MatMenuTrigger[] = [];
  private openPath: any[];
  constructor() {}
  openedPath(triggered: MatMenuTrigger) {
    let ancestors = [];
    let menu = triggered.menu;
    while (menu !== undefined) {
      ancestors = [menu, ...ancestors];
      menu = menu.parentMenu;
    }
    this.openPath = ancestors;
    this.triggers.forEach(trigger => {
      const check = trigger.menu;
      if (!ancestors.includes(check)) {
        trigger.closeMenu();
      }
    });
  }
  registerTrigger(menuTrigger) {
    if (this.triggers.findIndex(trigger => trigger === menuTrigger) == -1) {
      this.triggers = [...this.triggers, menuTrigger];
    }
  }
}
