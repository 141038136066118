<div
  *ngIf="arrayConfig.ngClass === 'expander'"
  class="expander"
  (click)="show = !show"
>
  <div *ngIf="arrayConfig?.label">{{ arrayConfig?.label }}</div>
  <mat-icon>{{ show ? 'expand_less' : 'expand_more' }}</mat-icon>
</div>
<div
  class="items-container"
  [style.visibility]="show ? 'visible' : 'hidden'"
  [style.height]="show ? 'auto' : '0px'"
  cdkDropList
  (cdkDropListDropped)="drop($event)"
>
  <div class="item-top"></div>

  <ng-container *ngIf="arrayConfig.items === 'group'; else elseTemplate">
    <div
      class="item"
      *ngFor="let item of array.controls; let index = index; let first = first"
      cdkDrag
    >
      <div
        class="sequence"
        [style.display]="arrayConfig.canSequence ? 'grid' : 'none'"
        cdkDragHandle
      >
        <mat-icon>drag_indicator</mat-icon>
      </div>
      <div class="item-left"></div>
      <div class="group" [class.first-row]="first">
        <app-group
          [groupConfig]="arrayConfig"
          [group]="getFormGroup(index)"
          [ngClass]="arrayConfig?.ngClass"
          [ngStyle]="arrayConfig?.ngStyle"
          (action)="emitAction($event)"
          (command)="formCommand($event, index)"
        ></app-group>
      </div>
      <div class="item-right"></div>
      <div
        class="remove"
        *ngIf="arrayConfig.canRemove"
        (click)="removeItem(index)"
      >
        <div class="ico"><mat-icon>delete</mat-icon></div>
      </div>
    </div>

    <div
      class="insert"
      *ngIf="arrayConfig.canInsert"
      (click)="insertGroup(arrayConfig.controls)"
    >
      <div class="ico">
        <mat-icon>add</mat-icon>
      </div>
      <div class="lbl">{{ arrayConfig?.label }}</div>
    </div>
  </ng-container>

  <ng-template #elseTemplate>
    <div
      class="item"
      *ngFor="let item of array.controls; let index = index; let first = first"
      cdkDrag
    >
      <div
        class="sequence"
        [style.display]="arrayConfig.canSequence ? 'grid' : 'none'"
        cdkDragHandle
      >
        <mat-icon>drag_indicator</mat-icon>
      </div>
      <div class="control">
        <app-control
          [ngClass]="arrayConfig?.ngClass"
          [ngStyle]="arrayConfig?.ngStyle"
          [controlConfig]="arrayConfig.controls[0]"
          [control]="getFormControl(index)"
        ></app-control>
      </div>
      <div
        class="remove"
        *ngIf="arrayConfig.canRemove"
        (click)="removeItem(index)"
      >
        <div class="ico"><mat-icon>delete</mat-icon></div>
      </div>
    </div>

    <div
      class="insert"
      *ngIf="arrayConfig.canInsert"
     
    >
      <div class="ico"  (click)="insertControl(arrayConfig.controls)">
        <mat-icon>add</mat-icon>
      </div>
      <div class="lbl">{{ arrayConfig?.label }}</div>
    </div>
  </ng-template>
</div>
