import {
  FormConfig,
  NuwaCommandEvent,
  NuwaActionEvent,
} from '../../editor.interface';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, FormArray } from '@angular/forms';

@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss'],
})
export class NuwaGroupComponent {
  @Input() groupConfig: FormConfig[];
  @Input() group: FormGroup;
  @Output() command = new EventEmitter<NuwaCommandEvent>();
  @Output() action = new EventEmitter<NuwaActionEvent>();

  constructor() {}

  getFormGroup(controlConfig): FormGroup {
    return this.group.get(controlConfig.key) as FormGroup;
  }
  getFormArray(controlConfig): FormArray {
    return this.group.get(controlConfig.key) as FormArray;
  }
  getFormControl(controlConfig): FormControl {
    return this.group.get(controlConfig.key) as FormControl;
  }
  emitAction(event): void {
    this.action.emit(event);
  }
  formAction(controlConfig, group): void {
    this.action.emit({
      actionConfig: controlConfig,
      actionFormGroup: group,
    });
  }
  formCommand(controlConfig, group): void {
    if (
      controlConfig.inputType &&
      controlConfig.inputType === 'calculate' &&
      controlConfig.displayFn
    ) {
      try {
        const calculateFn = new Function('return ' + controlConfig.displayFn)();
        const context = this.group.value;
        const result = calculateFn(context);
        if (result) {
          this.group.patchValue({ [controlConfig.key]: result });
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      this.command.emit(controlConfig);
    }
  }
  ifContext(controlConfig): boolean {
    if (controlConfig && controlConfig.displayIfFn) {
      try {
        const exprIfFn = new Function(
          'context',
          'return ' + controlConfig.displayIfFn
        );
        const ctxt = this.group.value;
        const result = exprIfFn(ctxt);
        return result;
      } catch (err) {
        console.log(err);
      }
    } else {
      return true;
    }
  }
}
