import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-data-grid-view',
  templateUrl: './data-grid-view.component.html',
  styleUrls: ['./data-grid-view.component.scss']
})
export class DataGridViewComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
