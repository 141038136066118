import { Component, OnInit, Input } from '@angular/core';
import { FormConfig } from '../../editor.interface';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss']
})
export class ToggleComponent implements OnInit {
  @Input() controlConfig: FormConfig;
  @Input() control: FormControl;
  constructor() { }

  ngOnInit() {
  }
  setValue(item) {
    this.control.setValue(item && item.value !== undefined ? item.value : item);
  }
}
