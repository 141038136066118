import { NuwaActionEvent } from './../../editor.interface';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  OnDestroy,
  AfterContentInit,
} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
  AbstractControl,
  FormArray,
} from '@angular/forms';
import { FormConfig, NuwaCommandEvent } from '../../editor.interface';
import { tap, debounceTime, take } from 'rxjs/operators';
import { Subscription, BehaviorSubject } from 'rxjs';
import {
  AngularFirestore,
  AngularFirestoreDocument,
  Action,
  DocumentSnapshotDoesNotExist,
  DocumentSnapshotExists,
} from '@angular/fire/firestore';
import { GrowlerService } from 'src/app/services/growler.service';
import { guid } from 'src/app/functions/guid';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class NuwaFormComponent implements OnInit, AfterContentInit, OnDestroy {
  private _status: 'initializing' | 'loading' | 'synced' | 'modified' | 'error';
  ready = new BehaviorSubject('loading');
  formConfig: FormConfig;
  private subscriptions = new Subscription();
  private docRef: AngularFirestoreDocument;
  private dataSource = {};
  @Input() formDefinition: FormConfig | string;
  @Input() formSource: string;
  @Input() commitCalculate : any;
  // tslint:disable-next-line: no-output-native
  @Output() stateChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() command = new EventEmitter<NuwaCommandEvent>();
  @Output() action = new EventEmitter<NuwaActionEvent>();
  @Output() closed = new EventEmitter<boolean>();
  formGroup: FormGroup;

  get value() {
    return { ...this.dataSource, ...this.formGroup.value };
  }
  constructor(
    private fb: FormBuilder,
    private afs: AngularFirestore,
    private growler: GrowlerService
  ) {}
  ngOnInit() {
    this.status = 'loading';
    this.getConfig();
  }

  getConfig() {
    console.log('getting config or writing config');
    // if (this.formConfig) {
    if (typeof this.formDefinition === 'string') {
      this.afs
        .doc(`controller/${this.formDefinition}`)
        .valueChanges()
        .pipe(
          take(1),
          tap((config) => {
            this.formConfig = config as FormConfig;
            console.log('form config = ', this.formConfig);
            this.getSource();
          })
        )
        .subscribe();
    } else if (this.formDefinition && 'key' in this.formDefinition) {
      const doc = this.afs
        .doc(`controller/${this.formDefinition.key}`)
        .set(this.formDefinition);
      this.formConfig = this.formDefinition;
      console.log('form config = ', this.formConfig);
      this.getSource();
    }
    // }
  }
  getSource() {
    console.log('getting source', `${this.formSource}`);

    this.docRef = this.afs.doc(`${this.formSource}`);
    const getDoc = this.docRef
      .snapshotChanges()
      .pipe(
        tap((data) => {
          console.log('source data = ', data.payload.data(), this.status);
          this.dataSource = data.payload.data();
          if (
            'controls' in this.formConfig &&
            this.status === 'loading' &&
            this.dataSource !== null &&
            this.dataSource !== undefined &&
            typeof this.dataSource === 'object'
          ) {
            console.log('creating controls');
            this.formGroup = this.createControl(
              this.formConfig.controls,
              this.dataSource
            );
            this.autoSave();
            console.log('ready');
            this.status = 'synced';
            this.ready.next('synced');
          } else if (this.status !== 'modified') {
            if (
              this.dataSource !== null &&
              this.dataSource !== undefined &&
              typeof this.dataSource === 'object' &&
              JSON.stringify(this.dataSource) !==
                JSON.stringify(this.formGroup.value)
            ) {
              // console.log(data);
              this.dataSource = this.dataSource;
              this.patchControl(
                this.formConfig.controls,
                this.formGroup,
                this.dataSource
              );
              this.formGroup.patchValue(this.dataSource, {
                onlySelf: true,
                emitEvent: false,
              });
            }
          }
        })
      )
      .subscribe();
    //   this.subscriptions.add(
    //     this.formGroup.valueChanges
    //       .pipe(
    //         tap(changes =>  this.status = 'modified'),
    //         debounceTime(2500),
    //         tap(changes => {
    //           // console.log(changes);
    //           // this.validateAllFormFields(this.formGroup);
    //            this.statusChanged.emit(this.formGroup.value);
    //            this.status = 'synced';
    //         })
    //       )
    //       .subscribe()
    //   );
    //   console.log(' form group created', new Date().valueOf());

    //   this.state = 'synced';
    //   // console.log(this.form);
    // } else if (this.state !== 'modified') {
    //   if (this.dataSource !== null
    //     && this.dataSource !== undefined
    //     && typeof this.dataSource === 'object'
    //     && JSON.stringify(this.dataSource) !== JSON.stringify(this.dataSource)) {
    //     // console.log(data);
    //     this.dataSource = this.dataSource;
    //     this.formGroup.patchValue(this.dataSource, { onlySelf: true, emitEvent: false });
    //   }
    // } else {
    //   // console.log('COULD NOT LOAD DATA', data);
    // }
    //   })
    // ).subscribe();
  }
  ngAfterContentInit() {}
  createControl(configuration: FormConfig[], data: any): FormGroup {
    const group = this.fb.group({},{updateOn: 'blur'});
    const uid = guid(8);
    configuration.forEach((config) => {
      if (config && config.valueFn) {
        try {
          if (config.valueFn === '@uid') {
            config.value = uid;
          } else {
            const exprValueFn = new Function(
              'context',
              'return ' + config.valueFn
            );
            const ctxt = configuration;
            const result = exprValueFn(ctxt);
            config.value = result();
          }
        } catch (err) {
          console.log(err);
        }
      }
      if (config.type === 'action' || config.type === 'command' || config.type === 'break') {
        return;
      } else if (config.type === 'group' && 'controls' in config) {
        const control = this.createControl(
          config.controls,
          data && data[config.key]
        );
        group.addControl(config.key, control);
      } else if (
        config.type === 'array' &&
        'items' in config &&
        config.items === 'group'
      ) {
        const arrayItems =
          data && data[config.key] && data[config.key].length > 0
            ? data[config.key]
            : [];
        const array = this.fb.array(
          [],
          this.bindValidations(config.validations || [])
        );
        arrayItems.map((item) => {
          const control = this.createControl(config.controls, item);
          array.push(control);
        });
        group.addControl(config.key, array);
      } else if (config.type === 'array') {
        const arrayItems =
          data && data[config.key] && data[config.key].length > 0
            ? data[config.key]
            : [];
        const array = this.fb.array(
          [],
          this.bindValidations(config.validations || [])
        );
        arrayItems.map((item) => {
          const control = new FormControl(item || config.value || null, {
            validators: this.bindValidations(config.validations || []),
            updateOn: 'blur',
          });
          array.push(control);
        });
        group.addControl(config.key, array);
      } else if (data && config.key) {
        let control;
        if (data[config.key]) {
          if (config.type === 'date') {
            const rawValue = data[config.key];
            if (rawValue instanceof Date) {
              control = new FormControl(rawValue.toISOString(), {
                validators: this.bindValidations(config.validations || []),
                updateOn: 'blur',
              });
            } else if (
              typeof rawValue === 'string' &&
              new Date(rawValue).toString() !== 'Invalid Date'
            ) {
              control = new FormControl(new Date(rawValue).toISOString(), {
                validators: this.bindValidations(config.validations || []),
                updateOn: 'blur',
              });
            } else if (rawValue.seconds) {
              control = new FormControl(
                new Date(rawValue.seconds * 1000).toISOString(),
                {
                  validators: this.bindValidations(config.validations || []),
                  updateOn: 'blur',
                }
              );
            } else if (rawValue.nanoseconds) {
              control = new FormControl(
                new Date(rawValue.nanoseconds / 1000000).toISOString(),
                {
                  validators: this.bindValidations(config.validations || []),
                  updateOn: 'blur',
                }
              );
            } else {
              control = new FormControl(new Date().toISOString(), {
                validators: this.bindValidations(config.validations || []),
                updateOn: 'blur',
              });
            }
          } else {
            control = new FormControl(data[config.key] || config.value, {
              validators: this.bindValidations(config.validations || []),
              updateOn: 'blur',
            });
          }
        } else {
          control = new FormControl(null, {
            validators: this.bindValidations(config.validations || []),
            updateOn: 'blur',
          });
        }
        group.addControl(config.key, control);
      }
    });
    return group;
  }
  patchControl(configuration: FormConfig[], group: AbstractControl, data: any) {
    configuration.forEach((config) => {
      if (config.type === 'action' || config.type === 'command' || config.type === 'break') {
        return;
      } else if (config.type === 'group' && 'controls' in config) {
      return
      } else if (
        config.type === 'array' &&
        'items' in config &&
        config.items === 'group' &&
        group.get([config.key]) instanceof FormArray
      ) {
        const arrayItems =
          data && data[config.key] && data[config.key].length > 0
            ? data[config.key]
            : [];
        const array = group.get([config.key]) as FormArray;
        arrayItems.map((item, i) => {
          const control = this.createControl(config.controls, item);
          if (i >= array.length) {
            array.push(control);
          }
        });
      } else if (
        config.type === 'array' &&
        group.get([config.key]) instanceof FormArray
      ) {
        const arrayItems =
          data && data[config.key] && data[config.key].length > 0
            ? data[config.key]
            : [];
        const array = group.get([config.key]) as FormArray;
        arrayItems.map((item, i) => {
          const control = new FormControl(item || config.value || null, {
            validators: this.bindValidations(config.validations || []),
            updateOn: 'blur',
          });
          if (i >= array.length) {
            array.push(control);
          }
        });
      }
    });
  }

  bindValidations(validations: any) {
    // if (validations.length > 0) {
    //   const validList = [];
    //   validations.forEach(valid => {
    //     validList.push(valid.validator);
    //   });
    //   return Validators.compose(validList);
    // }
    return null;
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  formCommand(commamndConfig: FormConfig) {
    if (commamndConfig && commamndConfig.key) {
      this.command.emit({
        control: commamndConfig.key,
        config: commamndConfig,
        record: this.value,
      });
    }
  }
  formAction($event) {
    this.action.emit($event);
  }
  emitValue() {
    this.validateAllFormFields(this.formGroup);
    this.stateChanged.emit(this.formGroup.value);
  }
  autoSave() {
    this.subscriptions = this.formGroup.valueChanges
      .pipe(
        tap((change) => {
          this.status = 'modified';
        }),
        // debounceTime(125),
        tap((change) => {
          if (this.formGroup.valid && this._status === 'modified') {
            this.setDoc();
          }
        })
      )
      .subscribe();
  }
  emitClose() {
    this.closed.emit(true);
  }
  getDocRef(path: string): any {
    if (path.split('/').length % 2) {
      return this.afs.doc(`${path}/${this.afs.createId()}`);
    } else {
      return this.afs.doc(path);
    }
  } 

  // Writes changes to Firestore
  async setDoc() {
    try {
      let commit= this.formGroup.value;
      if(this.commitCalculate && typeof this.commitCalculate === 'function') {
        try {
          const calculated = this.commitCalculate(commit)
          console.log(calculated)
          commit= {...commit,...calculated}
          debugger
        } catch (err) {
          debugger
        }
      }
      await this.docRef.set(commit, { merge: true });
      this.status = 'synced';
    } catch (err) {
      console.log(err);
      this.growler.growl(err.message);
      this.status = 'error';
    }
  }
  set status(val) {
    this._status = val;
    this.ready.next(val);
    // this.emitValue();
  }
  get status() {
    return this._status;
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
