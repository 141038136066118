import { Component, OnInit, Input } from '@angular/core';
import { FormConfig } from '../../editor.interface';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class NuwaSelectComponent {
  @Input() controlConfig: FormConfig;
  @Input() control: FormControl;
  constructor() {
  }
}
