import { Pipe, PipeTransform } from '@angular/core';
import { AngularFirestore, Action, DocumentSnapshotDoesNotExist, DocumentSnapshotExists } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Pipe({
  name: 'docpath'
})
export class DocPathPipe implements PipeTransform {
  constructor() {}

  transform(value: any, collection: string): string {
    return collection + '/' + value;
  }
}
