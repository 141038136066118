import { Pipe, PipeTransform } from '@angular/core';
import { isDate } from 'util';
import { isValidTimestamp } from '@firebase/util';

@Pipe({
  name: 'todate'
})
export class ToDatePipe implements PipeTransform {
  transform(value: any): any {
    if(value === null || value===undefined) {
      return null;
    }
    if (value instanceof Date) {
      return value;
    } else if (typeof value === 'string' && new Date(value).toString() !== 'Invalid Date') {
      return new Date(value);
    } else if (value.seconds) {
      return new Date(value.seconds * 1000);
    } else if (value.nanoseconds) {
      return new Date(value.nanoseconds / 1000000);
    }
    return null;
  }
}
