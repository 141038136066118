import { FormConfig } from './../../editor.interface';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-action',
  templateUrl: './action.component.html',
  styleUrls: ['./action.component.scss']
})
export class NuwaActionComponent implements OnInit {
  @Input() actionConfig: FormConfig;
  constructor() {}

  ngOnInit() {}
}
