import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ControllerRoutingModule } from './controller-routing.module';
import { ControllerComponent } from './controller.component';
import { DataGridViewComponent } from './data-grid-view/data-grid-view.component';

const COMPONENTS = [
  ControllerComponent,
  DataGridViewComponent,
];
@NgModule({
  declarations: COMPONENTS,
  imports: [
    CommonModule,
    ControllerRoutingModule
  ]
})
export class ControllerModule { }
