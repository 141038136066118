import { Component, OnInit, Input } from '@angular/core';
import { FormConfig } from '../../editor.interface';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-control',
  templateUrl: './control.component.html',
  styleUrls: ['./control.component.scss']
})
export class NuwaControlComponent implements OnInit {
  @Input() controlConfig: FormConfig;
  @Input() control: FormControl;

  constructor() { }

  ngOnInit() {
  }

}
