import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { FormConfig } from '../editor.interface';
import { fromOptions } from '../../useful-helpers/fromOptions';
import { NuwaMenuItem } from '../../nuwaMenu/NuwaMenuItem';
const findNuwaMenuItemByValue = (findValue: any, menuItem: NuwaMenuItem) => {
  if (
    menuItem &&
    menuItem.emits &&
    menuItem.emits.value &&
    menuItem.emits.value === findValue
  ) {
    return menuItem;
  } else if (
    menuItem &&
    menuItem.children &&
    menuItem.children.length &&
    menuItem.children.length > 0
  ) {
    for (let index = 0; index < menuItem.children.length; index++) {
      const child = menuItem.children[index];
      const rtn = findNuwaMenuItemByValue(findValue, child);
      if (rtn !== null) {
        return rtn;
      }
    }
  }
  return null;
};
@Component({
  selector: 'app-menu',
  template: `
    <nuwa-menu
      
      style="place-content: center; display: grid;"
      [id]="'templatesMenu'"
      [menu]="menu"
      (menuAction)="setValue($event)"
    >
      <div class="display-label">{{ controlConfig.label }}</div>
      <div class="display-value">{{ display }}</div>
    </nuwa-menu>
   
  `,
  styles: [
    `
      :host {
        display: grid;
      }
      .display {
        display: grid;
        margin-left: 10px;
      }
      div.display-label {
        font-size: 10px;
        margin-bottom: 5px;
        color: #003264;
      }

      div.display-value {
        font-size: 12px;
      }
    `,
  ],
})
export class MenuComponent implements OnInit {
  @Input() controlConfig: FormConfig;
  @Input() control: FormControl;
  menu: NuwaMenuItem;
  display: any;
  constructor() {}

  ngOnInit() {
    this.menu = {
      displayName: 'root',
      children: this.controlConfig.options as NuwaMenuItem[],
    };
    const initMenuItem = findNuwaMenuItemByValue(this.control.value, this.menu);
    this.display = initMenuItem ? initMenuItem.displayName : '';
  }
  setValue(item) {
    const valueToSet = item && item.value !== undefined ? item.value : item;
    this.control.setValue(valueToSet);
    this.display = findNuwaMenuItemByValue(valueToSet, this.menu).displayName;
  }
}
