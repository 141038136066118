import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { FormConfig } from '../editor.interface';
@Component({
  selector: 'app-display',
  template: `
    <div class="display-label" >{{ controlConfig?.label }}</div>
    <div class="display-value">
      <ng-container *ngIf="!controlConfig.inputType">
        {{ control?.value }}
      </ng-container>
      <ng-container *ngIf="controlConfig?.inputType === 'stripe'"> £{{ control?.value | stripe }} </ng-container>
      <ng-container *ngIf="controlConfig?.inputType === 'currency'"> £{{ control?.value | number: '0.0-0' }} </ng-container>
      <ng-container *ngIf="controlConfig?.inputType === 'integer'">
        {{ control?.value | number: '0.0-0' }}
      </ng-container>
      <ng-container *ngIf="controlConfig?.inputType === 'decimal'">
        {{ control?.value | number: '0.2-2' }}
      </ng-container>
      <ng-container *ngIf="controlConfig?.inputType === 'date'">
        {{ control?.value | todate | date: 'dd/MM/y' }}
      </ng-container>
      <ng-container *ngIf="controlConfig?.inputType === 'timestamp'">
        {{ control?.value | todate | date: 'dd/MM/y HH:mm:ss' }}
      </ng-container>
      <ng-container *ngIf="controlConfig?.inputType === 'text'">
        {{ control?.value }}
      </ng-container>
      <ng-container *ngIf="controlConfig?.inputType === 'length'">
        {{ control?.value?.length | fileSize }}
      </ng-container>
      <ng-container *ngIf="controlConfig?.inputType === 'image'">

      </ng-container>
      <ng-container *ngIf="controlConfig?.inputType === 'svg'">
<div [innerHTML]="control?.value | safehtml"></div>
</ng-container>

      <!-- ng-container *ngIf="controlConfig?.inputType === 'template'">
         {{ control.value ? templateFn(controlConfig.inputType, control.value) : '' }}
      </!-->
    </div>
  `,
  styles: [
    `
      :host,
      .editor-full-width {
        display: grid;
      }
      div.display-label {
        font-size: 10px;
        margin-bottom: 5px;
        color:#003264;
      }

      div.display-value {
        font-size: 12px;
      }
    `
  ]
})
export class DisplayComponent implements OnInit {
  @Input() controlConfig: FormConfig;
  @Input() control: FormControl;

  constructor() {}
  ngOnInit() {}
}
