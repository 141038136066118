import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { FormConfig } from '../editor.interface';
@Component({
  selector: 'app-date',
  template: `
    <mat-form-field  class="editor-full-width" appearance="standard">
      <mat-label style="color:#0064AF">{{controlConfig?.label}}</mat-label>
      <input matInput [matDatepicker]="picker" [formControl]="control" [value]="control?.value | todate"/>
      <mat-hint>{{controlConfig?.hint}}</mat-hint>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
      <mat-hint></mat-hint>
      <ng-container *ngFor="let validation of controlConfig?.validations" ngProjectAs="mat-error">
        <mat-error *ngIf="control.hasError(validation.name)">{{ validation.message }}</mat-error>
      </ng-container>
    </mat-form-field>
  `,
  styles: [
    `
      :host,
      .editor-full-width {
        display: grid;  font-size: 12px;
        max-height: 38px;
      }
    `
  ]
})
export class DateComponent implements OnInit {
  @Input() controlConfig: FormConfig;
  @Input() control: FormControl;

  constructor() {}
  ngOnInit() {}


}
