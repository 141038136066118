
export function guid(len: number) {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  let rtn = '';
  for (let index = 0; index < Math.ceil(len / 4); index++) {
    rtn = rtn + s4();
  }
  return rtn.slice(0, len);
}
