import { FormConfig } from './../../editor.interface';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-command',
  templateUrl: './command.component.html',
  styleUrls: ['./command.component.scss']
})
export class NuwaCommandComponent implements OnInit {
  @Input() commandConfig: FormConfig;
  constructor() {}

  ngOnInit() {}
}
