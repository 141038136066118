import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-get-value',
  templateUrl: './dialog-get-value.component.html',
  styleUrls: ['./dialog-get-value.component.css']
})
export class DialogGetValueComponent {
  constructor(
    public dialogRef: MatDialogRef<DialogGetValueComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  get options() {
    return ' '
      .repeat(Math.ceil((this.data.to - this.data.from) / this.data.step))
      .split('')
      .map((v, i) => {
        return this.data.from + i * this.data.step;
      });
  }
  selected(option): void {
    this.dialogRef.close(option);
  }
}
