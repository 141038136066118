import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { FormConfig } from '../editor.interface';
@Component({
  selector: 'app-checkbox',
  template: `
    <div  class="editor-checkbox">
      <mat-checkbox [formControl]="control" (click)="toggle(control.value)" color="primary">{{ controlConfig.label }}</mat-checkbox>
    </div>
  `,
  styles: [
    `
      :host,
      .editor-checkbox {
        display: grid;  font-size: 12px; position: relative; min-height:24px
      }
    `
  ]
})
export class CheckboxComponent implements OnInit {
  @Input() controlConfig: FormConfig;
  @Input() control: FormControl;
  constructor() {}
  ngOnInit() {}
  toggle(value) {
    this.control.setValue(!value)
  }
}

