import { GrowlerService } from './../../services/growler.service';
import { Component, OnInit, Output, EventEmitter, Input, Inject } from '@angular/core';
import { tap, finalize, take } from 'rxjs/operators';
import { AngularFireUploadTask, AngularFireStorage } from '@angular/fire/storage';
import { Observable, timer } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'file-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent {
  @Output() uploadURL = new EventEmitter<string>();
  // Main task
  task: AngularFireUploadTask;

  // Progress monitoring
  percentage: Observable<number>;

  // Download URL
  downloadURL: Observable<string>;

  constructor(
    private growler: GrowlerService,
    private storage: AngularFireStorage,
    public bottomSheet: MatBottomSheetRef<any>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public options: any) { }

  doUpload(event: FileList) {
    // The File object
    const file = event.item(0);

    // Client-side validation example
    if (file.type.split('/')[0] !== 'image') {
      console.error('unsupported file type :( ');
      return;
    }

    // The storage path
    const uploadPath = 'uploads/' + new Date().valueOf().toString(36) + '.' + file.name.split('.').pop();
    // const destinationPath = this.options.destination;
    const destinationRef = this.storage.ref(uploadPath);
    this.growler.growl('Upload Starting');
    this.task = this.storage.upload(uploadPath, file, { customMetadata: this.options });

    // Progress monitoring
    this.percentage = this.task.percentageChanges();
    this.task.snapshotChanges().pipe(
      // The file's download URL
      finalize(() => {
        this.growler.growl('Upload Complete. Processing Size Adjustments');
      }),
    ).subscribe();
    const getUrl = timer(2500, 7500).pipe(
      take(2),
      tap(
        (i) => {
          this.growler.growl('Processing' + '.'.repeat(i));
          // console.log(i, destinationPath);
          try {
            const watcher = destinationRef.getDownloadURL().pipe(
              tap(url => {
                getUrl.unsubscribe();
                this.bottomSheet.dismiss(url);
              }, (err) => { console.log(err); })
            ).subscribe();
          } catch (err) {
            console.log(err);
          }
          if (i === 2) {
            this.bottomSheet.dismiss(null);
          }
        }
      )).subscribe();
  }

  // Determines if the upload task is active
  isActive(snapshot) {
    return snapshot.state === 'running' && snapshot.bytesTransferred < snapshot.totalBytes;
  }
}
