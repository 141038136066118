<div class="data-grid-list-container mat-elevation-z0">
  <mat-table [dataSource]="Data" matSort [trackBy]="trackById">
    <ng-container matColumnDef="filter">
      <mat-header-cell *matHeaderCellDef class="filter-column" (click)="toggleANDOR()">
        <div class="filter-control">
        <div><small>MATCH</small></div>
        <div><b>{{ filtersAndOr === 'AND' ? 'ALL' : 'ANY' }}</b></div>
        </div>
      </mat-header-cell>
      <mat-cell *matCellDef [style.flex]="'0 0 0'"></mat-cell>
    </ng-container>
    <ng-container *ngFor="let column of dataGridColumnKeys; let colIdx = index">
      <ng-container [matColumnDef]="column">
        <mat-header-cell *matHeaderCellDef [ngStyle]="getStyle(column)" [style.flex]="getFlex(column) | safecss">
          <ng-container *ngIf="getHeaderKind(column) === 'column'">
            <mat-form-field appearance="fill" class="filter-criteria">
              <mat-label>{{ getLabel(column) }}</mat-label>
              <input matInput [value]="getFilter(column)" (keyup)="setFilter(column, $event.target.value)" />
            </mat-form-field>
            <div mat-sort-header class="sort ico"></div>
          </ng-container>
          <ng-container *ngIf="getHeaderKind(column) === 'label'">
            <mat-label>{{ getLabel(column) }}</mat-label>
          </ng-container>
        </mat-header-cell>

        <mat-cell *matCellDef="let record" [ngStyle]="getStyle(column)" [style.flex]="getFlex(column) | safecss">
          <ng-container *ngIf="getTemplate(column) === 'text' && getCellKind(column) === 'control'">
            <div class="control-button" (click)="controlClick(column, record)">{{ getLabel(column) }}</div>
          </ng-container>
          <ng-container *ngIf="getTemplate(column) === 'icon' && getCellKind(column) === 'control'">
            <mat-icon class="control-icon" (click)="controlClick(column, record)">{{ getLabel(column) }}</mat-icon>
          </ng-container>
          <ng-container *ngIf="getTemplate(column) === 'icon' && getCellKind(column) === 'column'">
            <mat-icon>{{ record && record[column] ? record[column] : '' }}</mat-icon>
          </ng-container>
          <ng-container *ngIf="getTemplate(column) === 'stripe'">
            £{{ (record && record[column] ? record[column] : 0) | stripe }}
          </ng-container>
          <ng-container *ngIf="getTemplate(column) === 'currency'">
            £{{ (record && record[column] ? record[column] : 0) | number: '0.0-0' }}
          </ng-container>
          <ng-container *ngIf="getTemplate(column) === 'integer'">
            {{ (record && record[column] ? record[column] : 0) | number: '0.0-0' }}
          </ng-container>
          <ng-container *ngIf="getTemplate(column) === 'decimal'">
            {{ (record && record[column] ? record[column] : 0) | number: '0.2-2' }}
          </ng-container>
          <ng-container *ngIf="getTemplate(column) === 'date'">
            {{ record && record[column] ? (record[column] | todate | date: 'dd/MM/y') : '' }}
          </ng-container>
          <ng-container *ngIf="getTemplate(column) === 'timestamp'">
            {{ record && record[column] ? (record[column] | todate | date: 'dd/MM/y HH:mm:ss') : '' }}
          </ng-container>
          <ng-container *ngIf="getTemplate(column) === 'text'">
            {{ record && record[column] ? record[column] : '' }}
          </ng-container>
          <ng-container *ngIf="getTemplate(column) === 'count'">
            {{ record && record[column] && record[column].length ? (record[column].length) : 'empty' }}
          </ng-container>
          <ng-container *ngIf="getTemplate(column) === 'csv'">
            {{ record && record[column] && record[column].length ? (record[column].toString()) : ' ' }}
          </ng-container>
          <ng-container *ngIf="getTemplate(column) === 'length'">
            {{ record && record[column] && record[column].length ? (record[column].length | fileSize) : 'empty' }}
          </ng-container>
          <ng-container *ngIf="getTemplate(column) === 'template' && getCellKind(column) === 'control'">
            <div (click)="controlClick(column, record)">{{ record ? templateFn(column,record) : '' }}</div>
          </ng-container>
        </mat-cell>
      </ng-container>
    </ng-container>

    <mat-header-row *matHeaderRowDef="dataGridColumnKeysWithControls; sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: dataGridColumnKeysWithControls"></mat-row>
  </mat-table>
  <mat-paginator [pageSizeOptions]="[100, 250, 500, 750, 1000]" showFirstLastButtons></mat-paginator>
  <button *ngIf="canAdd===true" class="fab-add" mat-fab (click)="clickAdd()" color="primary">
      <mat-icon class="mat-18">add</mat-icon>
  </button>
</div>
