import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stripnumbers'
})
export class StripNumbersPipe implements PipeTransform {
  transform(value: any): any {
    if (typeof value === 'string') {
      const numbers = '1234567890'.split('');
      return value.split('').filter(c=>!numbers.includes(c)).join('');
    }
    return value;
  }
}
