// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  functionsURL: 'https://us-central1-gourmet-guide-co-uk.cloudfunctions.net/',
  firebase: {
    apiKey: "AIzaSyBiBy_1CUiRR8g9qC_LpYZh1GvyIHr7Uew",
    authDomain: "gourmet-guide-co-uk.firebaseapp.com",
    databaseURL: "https://gourmet-guide-co-uk.firebaseio.com",
    projectId: "gourmet-guide-co-uk",
    storageBucket: "gourmet-guide-co-uk.appspot.com",
    messagingSenderId: "958996085050",
    appId: "1:958996085050:web:5acf75ca6008300f547803",
    measurementId: "G-G0TB0ZE154"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
