import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, OnInit, Input, NgZone, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { take } from 'rxjs/operators';
import { FormConfig } from '../editor.interface';
@Component({
  selector: 'app-textarea',
  template: `
    <mat-form-field class="editor-full-width" appearance="standard" >
      <mat-label style="color:#0064AF">{{controlConfig?.label}}</mat-label>
      <textarea matInput [formControl]="control" cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"></textarea>
      <mat-hint>{{controlConfig?.hint}}</mat-hint>
      <ng-container *ngFor="let validation of controlConfig?.validations" ngProjectAs="mat-error">
        <mat-error *ngIf="control.hasError(validation.name)">{{ validation?.message }}</mat-error>
      </ng-container>
    </mat-form-field>
  `,
  styles: [
    `
      :host,
      .editor-full-width {
        display: grid;
        font-size: 12px;
        height: auto;
      }
    `
  ]
})
export class TextAreaComponent implements OnInit {
  @Input() controlConfig: FormConfig;
  @Input() control: FormControl;

  constructor(private _ngZone: NgZone) {}

  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1))
        .subscribe(() => this.autosize.resizeToFitContent(true));
  }
  ngOnInit() {}
}
