import { NuwaActionComponent } from './nuwaEditor/containers/action/action.component';
import { DialogGetValueComponent } from './dialog-get-value/dialog-get-value.component';
import { ToggleComponent } from './nuwaEditor/editors/toggle/toggle.component';
import { TextAreaComponent } from './nuwaEditor/editors/textarea.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppMaterialModule } from '../app.material.module';
import { PipesModule } from '../pipes/pipes.module';
import { UploadComponent } from './upload/upload.component';
import { DataGridComponent } from './data-grid/data-grid.component';
import { DropZoneDirective } from './directives/dropzone.directive';
import { InputComponent } from './nuwaEditor/editors/input.component';
import { DateComponent } from './nuwaEditor/editors/date.component';
import { RadiobuttonComponent } from './nuwaEditor/editors/radiobutton.component';
import { CheckboxComponent } from './nuwaEditor/editors/checkbox.component';
import { NuwaGroupComponent } from './nuwaEditor/containers/group/group.component';
import { NuwaFormComponent } from './nuwaEditor/containers/form/form.component';
import {
  NuwaArrayComponent,
  ConfirmDeleteDialogComponent,
} from './nuwaEditor/containers/array/array.component';
import { DisplayComponent } from './nuwaEditor/editors/display.component';
// import { HtmlComponent } from '../../../archive/html.component';
// import {
//   AngularEditorModule,
//   AngularEditorComponent,
// } from '@kolkov/angular-editor';
import { NuwaCommandComponent } from './nuwaEditor/containers/command/command.component';
// import { PreviewComponent } from './preview/preview.component';
import { NuwaSelectorComponent } from './selector/selector.component';
import { NuwaControlComponent } from './nuwaEditor/containers/control/control.component';
import { NuwaSelectComponent } from './nuwaEditor/editors/select/select.component';
// import { AffiliateComponent } from '../../../archive/affiliate/affiliate.component';
import { AnchorComponent } from './nuwaEditor/editors/anchor.component';
// import { ChipsComponent } from '../../../archive/chips/chips.component';
import { ImageComponent } from './nuwaEditor/editors/image.component';
// import { NuwaMultiSelectComponent } from '../../../archive/multi-select/multi-select.component';
import { NuwaMenuComponent } from './nuwaMenu/menu.component';
import { MenuComponent } from './nuwaEditor/editors/menu.component';
import { SlideToggleComponent } from './nuwaEditor/editors/slidetoggle.component';

const DECLARATIONSandEXPORTS = [
  DropZoneDirective,
  UploadComponent,
  DataGridComponent,
  InputComponent,
  ImageComponent,
  TextAreaComponent,
  // HtmlComponent,
  NuwaSelectComponent,
  // NuwaMultiSelectComponent,
  DateComponent,
  AnchorComponent,
  MenuComponent,
  RadiobuttonComponent,
  CheckboxComponent,
  SlideToggleComponent,
  // ChipsComponent,
  ToggleComponent,
  NuwaSelectorComponent,
  DisplayComponent,
  NuwaGroupComponent,
  NuwaCommandComponent,
  NuwaActionComponent,
  NuwaControlComponent,
  NuwaFormComponent,
  NuwaArrayComponent,
  NuwaMenuComponent,
  // PreviewComponent,
  DialogGetValueComponent,
  ConfirmDeleteDialogComponent,
];

@NgModule({
  declarations: DECLARATIONSandEXPORTS,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AppMaterialModule,
    PipesModule,
    // AngularEditorModule,
  ],
  providers: [],
  exports: DECLARATIONSandEXPORTS,
  entryComponents: [
    DisplayComponent,
    // PreviewComponent,
    // AffiliateComponent,
    NuwaSelectorComponent,
    UploadComponent,
    DialogGetValueComponent,
  ],
})
export class ComponentsModule {}
