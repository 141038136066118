import {
  FormConfig,
  NuwaActionEvent,
  NuwaCommandEvent,
} from '../../editor.interface';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
  FormArray,
} from '@angular/forms';
import { moveItemInArray, CdkDragDrop } from '@angular/cdk/drag-drop';
import * as _ld from 'lodash-es';
import { guid } from 'src/app/functions/guid';
import { MatDialog } from '@angular/material/dialog';

@Component({
  template: `
    <h1 mat-dialog-title align="center">CONFIRM DELETE</h1>
    <div mat-dialog-content>
      Are you sure you want to permenently delete this entry
    </div>
    <div mat-dialog-actions align="center">
      <button mat-button mat-dialog-close cdkFocusInitial>CANCEL</button>
      <button mat-raised-button [mat-dialog-close]="true" color="warn">
        CONFIRM
      </button>
    </div>
  `,
  styles: [``],
})
export class ConfirmDeleteDialogComponent {}

@Component({
  selector: 'app-array',
  templateUrl: './array.component.html',
  styleUrls: ['./array.component.scss'],
})
export class NuwaArrayComponent {
  @Input() arrayConfig: FormConfig;
  @Input() array: FormArray;
  @Output() command = new EventEmitter<NuwaCommandEvent>();
  @Output() action = new EventEmitter<NuwaActionEvent>();

  show = true;

  constructor(private fb: FormBuilder, public dialog: MatDialog) {}

  getFormGroup(index: number): FormGroup {
    return this.array.controls[index] as FormGroup;
  }
  getFormControl(index: number): FormControl {
    return this.array.controls[index] as FormControl;
  }
  createControl(configuration: FormConfig[]): FormGroup {
    const group = this.fb.group({});
    const uid = guid(8);
    configuration.forEach((config) => {
      if (config && config.valueFn) {
        try {
          if (config.valueFn === '@uid') {
            config.value = uid;
          } else {
            const _ = _ld;
            const exprValueFn = new Function(
              'context',
              'return ' + config.valueFn
            );
            const ctxt = configuration;
            const result = exprValueFn(ctxt);
            config.value = result();
          }
        } catch (err) {
          console.log(err);
        }
      }
      if (config.type === 'action' || config.type === 'command') {
        return;
      } else if (config.type === 'group' && 'controls' in config) {
        const control = this.createControl(config.controls);
        group.addControl(config.key, control);
      } else if (config.type === 'array') {
        const control = this.fb.array(
          [],
          this.bindValidations(config.validations || [])
        );
        group.addControl(config.key, control);
      } else {
        const control = this.fb.control(
          config.value,
          this.bindValidations(config.validations || [])
        );
        group.addControl(config.key, control);
      }
    });
    return group;
  }
  insertGroup(itemConfig): void {
    if (Array.isArray(itemConfig)) {
      const control = this.createControl(itemConfig);
      this.array.push(control);
    }
  }
  insertControl(config): void {
    const control = this.fb.control(
      config.value || null,
      this.bindValidations(config.validations || [])
    );
    this.array.push(control);
  }
  removeItem(index: number): void {
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.array.removeAt(index);
      }
    });
  }

  bindValidations(validations: any): any {
    if (validations.length > 0) {
      const validList = [];
      validations.forEach((valid) => {
        validList.push(valid.validator);
      });
      return Validators.compose(validList);
    }
    return null;
  }
  drop(event: CdkDragDrop<any[]>): void {
    const update = [...this.array.getRawValue()];
    moveItemInArray(update, event.previousIndex, event.currentIndex);
    this.array.patchValue(update);
  }
  formCommand(event, index): void {
    this.command.emit({
      config: this.arrayConfig.controls[index],
      record: this.array.value[index],
      control: this.array.get(index),
      // formGroup:
      //   event.formGroup &&
      //   event.formGroup !== undefined &&
      //   event.formGroup !== null
      //     ? event.FormGroup
      //     : this.getFormGroup(index),
    });
  }
  formAction(event, index): void {
    this.action.emit({
      actionConfig: this.arrayConfig.controls[index],
      actionFormGroup: this.array.get(index),
    });
  }
  emitAction($event): void {
    this.action.emit($event);
  }
}
