import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class GrowlerService {
  constructor(private snackBar: MatSnackBar) {}

  growl(message: string, duration?: number) {
    duration = duration ? duration : 5000;
    this.snackBar.open(message, '', {
      duration
    });
  }
}
