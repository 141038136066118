<ng-template #inputTemplate>
<div class="file">
  <label class="file-label">
    <input class="file-input" type="file" (change)="doUpload($event.target.files)" />
    <span class="file-cta">
      <span class="file-icon">
        <i class="fa fa-upload"></i>
      </span>
      <span class="file-label">
        Choose A File To Upload
      </span>
    </span>
  </label>
</div>
</ng-template>
<ng-container *ngIf="percentage | async as pct; else inputTemplate">
  <div *ngIf="pct < 100">
    <progress class="progress is-info" [value]="pct" max="100"> </progress>
    {{ pct | number }}%
  </div>
  <div *ngIf="pct === 100">
    Upload Complete
  </div>
</ng-container>
