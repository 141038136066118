import { Pipe, PipeTransform } from '@angular/core';
import { AngularFirestore, Action, DocumentSnapshotDoesNotExist, DocumentSnapshotExists } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Pipe({
  name: 'doc'
})
export class DocPipe implements PipeTransform {
  constructor(private afs: AngularFirestore) {}

  transform(value: any, collection: string): Observable<any> {
    const docRef = this.afs.doc(collection + '/' + value);
    return docRef.snapshotChanges().pipe(
      map((doc: Action<DocumentSnapshotDoesNotExist | DocumentSnapshotExists<any>>) => {
        return doc.payload.data();
      })
    );
  }
}
