<ng-container *ngIf="groupConfig && groupConfig['controls']">
  <ng-container *ngFor="let controlConfig of groupConfig['controls']">
    <ng-container *ngIf="ifContext(controlConfig)">
      <ng-container [ngSwitch]="controlConfig.type">
        <ng-container *ngSwitchCase="'group'">
          <app-group
            [groupConfig]="controlConfig"
            [group]="getFormGroup(controlConfig)"
            (command)="formCommand($event)"
            (action)="emitAction($event)"
            [ngClass]="controlConfig?.ngClass"
            [ngStyle]="controlConfig?.ngStyle"
          ></app-group>
        </ng-container>
        <ng-container *ngSwitchCase="'array'">
          <app-array
            [arrayConfig]="controlConfig"
            [array]="getFormArray(controlConfig)"
            (command)="formCommand($event)"
            (action)="emitAction($event)"
            [ngClass]="controlConfig?.ngClass"
            [ngStyle]="controlConfig?.ngStyle"
          ></app-array>
        </ng-container>
        <ng-container *ngSwitchCase="'action'">
          <app-action
            (click)="formAction(controlConfig, group)"
            [actionConfig]="controlConfig"
            [ngClass]="controlConfig?.ngClass"
            [ngStyle]="controlConfig?.ngStyle"
          >
          </app-action>
        </ng-container>
        <ng-container *ngSwitchCase="'command'">
          <app-command
            (click)="formCommand(controlConfig, group)"
            [commandConfig]="controlConfig"
            [ngClass]="controlConfig?.ngClass"
            [ngStyle]="controlConfig?.ngStyle"
          >
          </app-command>
        </ng-container>

        <ng-container *ngSwitchCase="'headline'">
          <div
            class="mat-headline"
            [ngClass]="controlConfig?.ngClass"
            [ngStyle]="controlConfig?.ngStyle"
          >
            <div *ngIf="controlConfig?.label">{{ controlConfig?.label }}</div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'break'">
          <div
            class="break"
            [ngClass]="controlConfig?.ngClass"
            [ngStyle]="controlConfig?.ngStyle"
          >
            <div *ngIf="controlConfig?.label">{{ controlConfig?.label }}</div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'label'">
          <div
            [ngClass]="controlConfig?.ngClass"
            [ngStyle]="controlConfig?.ngStyle"
          >
            <div *ngIf="controlConfig?.label">{{ controlConfig?.label }}</div>
          </div>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <ng-container *ngIf="getFormControl(controlConfig) as frmControl">
            <app-control
              [ngClass]="controlConfig?.ngClass"
              [ngStyle]="controlConfig?.ngStyle"
              [controlConfig]="controlConfig"
              [control]="frmControl"
            ></app-control>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
