import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { FormConfig } from '../editor.interface';
@Component({
  selector: 'app-radiobutton',
  template: `
    <div class="editor-full-width">
      <label class="radio-label-padding" style="color:#0064AF">{{ controlConfig.label }}:</label>
      <mat-radio-group [formControl]="control">
        <mat-radio-button *ngFor="let item of controlConfig.options" [value]="item">{{ item }}</mat-radio-button>
      </mat-radio-group>
    </div>
  `,
  styles: [
    `
      :host,
      .editor-full-width {
        display: grid;  font-size: 12px;
      }
    `
  ]
})
export class RadiobuttonComponent implements OnInit {
  @Input() controlConfig: FormConfig;
  @Input() control: FormControl;

  constructor() {}
  ngOnInit() {}
}
