import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { FormConfig } from '../editor.interface';
@Component({
  selector: 'app-anchor',
  template: `
    <a *ngIf="control?.value" [href]="control?.value | safeurl" target="_">
      <div class="command">
        <div>{{ controlConfig?.label }}</div>
      </div>
    </a>
    <div *ngIf="!control?.value" class="no-link">
    <div class="command">
    <div>{{ controlConfig?.label }}</div>
  </div>
</div>
  `,
  styles: [
    `
      :host-context(.button-text) a,
      :host-context(.button-text) .no-link .command {
        display: grid;
        place-content: center;
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2), 0 0 0 0 rgba(0, 0, 0, 0.14), 0 0 0 0 rgba(0, 0, 0, 0.12);
        border-color: rgba(0, 0, 0, 0.25);
        border-width: 1px;
        border-style: solid;
        padding: 0 15px;
        line-height: 34px;
        box-sizing: border-box;
        position: relative;
        user-select: none;
        cursor: pointer;
        outline: 0;
        white-space: nowrap;
        text-decoration: none;
        vertical-align: baseline;
        text-align: center;
        margin: 0px;
        min-width: 64px;
        max-height: 38px;
        line-height: 36px;
        padding: 0 16px;
        border-radius: 4px;
        overflow: visible;
        color: inherit;
        background: 0 0;
        font-family: Roboto, 'Helvetica Neue', sans-serif;
        font-size: 14px;
        font-weight: 500;
        color: inherit;
        cursor: pointer;
        text-decoration: none;
      }
      :host-context(.button-text) .no-link .command {
      opacity:.25;
      }
        `
  ]
})
export class AnchorComponent implements OnInit {
  @Input() controlConfig: FormConfig;
  @Input() control: FormControl;

  constructor() {}
  ngOnInit() {
    console.log(this.controlConfig);
    console.log(this.control);
  }
}
