import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  AfterViewInit,
  HostListener
} from '@angular/core';
import { NuwaMenuItem } from './NuwaMenuItem';
import {
  MatMenuTrigger,
  MatMenu,
  MatMenuItem,
  MenuPositionX,
  MenuPositionY
} from '@angular/material/menu';
import { NuwaMenuService } from './menu.service';
import { MatDialog } from '@angular/material/dialog';
import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { paths } from './dot-path';
@Component({
  // tslint:disable-next-line: component-selector
  selector: 'nuwa-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class NuwaMenuComponent implements AfterViewInit {
  @Input() id: string;
  @Input() menu: NuwaMenuItem;
  @Input() context: any;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  @Output() menuAction = new EventEmitter();
  children$ = new BehaviorSubject(null);
  xPosition: MenuPositionX = 'before';
  yPosition: MenuPositionY = 'below';

  constructor(
    private menuService: NuwaMenuService,
    // private db: AngularFirestore,
    // public dialog: MatDialog
  ) {}

  ngAfterViewInit() {
    if (this.trigger !== undefined) {
      this.menuService.registerTrigger(this.trigger);
    }
    // if (this.id) {
    //   this.db
    //     .collection('acms-menus')
    //     .doc(this.id)
    //     .set({ id: this.id, ...paths(this.menu) }, { merge: true });
    // }
  }
  @HostListener('click', ['$event.target'])
  onClick(btn) {
    this.open({ X: this.xPosition, Y: this.yPosition });
  }
  open(position?: { X?: 'before' | 'after'; Y?: 'above' | 'below' }) {
    if (position !== undefined) {
      this.xPosition =
        position.X && position.X === 'after' ? 'after' : 'before';
      this.yPosition = position.Y && position.Y === 'above' ? 'above' : 'below';
    }
    if (this.trigger && this.trigger.openMenu) {
      this.children$.next(this.menu.children);
      this.trigger.openMenu();
    }
  }
  emit(event) {
    if (this.menu.emits) {
      const emitter = JSON.parse(JSON.stringify(this.menu.emits));
      this.menuAction.emit(emitter);
    }
  }
  // inputDialog(event) {
  //   if (this.menu && this.menu.inputs) {
  //     const inputs = Array.isArray(this.menu.inputs)
  //       ? this.menu.inputs.filter(i => i && i.fieldPath)
  //       : [this.menu.inputs];

  //     if (inputs.length > 0) {
  //       const dialogRef = this.dialog.open(NuwaInputDialogComponent, {
  //         width: '250px',
  //         data: { context: this.context ? this.context : {}, inputs }
  //       });

  //       this.menuAction.emit(
  //         dialogRef.afterClosed().pipe(
  //           map(result => {
  //             return inputs.reduce((acc, i) => {
  //               return { ...acc, [i.fieldPath]: result[i.fieldPath] };
  //             }, {});
  //           })
  //         )
  //       );
  //     }
  //   }
  //   if (this.menu && this.menu.nuwaForm) {
  //     const dialogRef = this.dialog.open(NuwaInputDialogComponent, {
  //       data: {
  //         snapshot: this.context ? this.context : {},
  //         nuwaForm: this.menu.nuwaForm
  //       }
  //     });

  //     this.menuAction.emit(
  //       dialogRef.afterClosed().pipe(
  //         map(result => {
  //           console.log(
  //             'NuwaInputDialog -> nuwaForm returned: ' + JSON.stringify(result)
  //           );
  //           return result;
  //         })
  //       )
  //     );
  //   }
  // }
  openedMenu(event: MouseEvent) {
    if (event && event.stopPropagation !== undefined) {
      event.stopPropagation();
    }
    this.menuService.openedPath(this.trigger);
  }
  closedMenu(event: MouseEvent) {
  }
  getChildWithPath(childIdx) {}
}
