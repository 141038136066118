import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { FormConfig } from '../editor.interface';
@Component({
  selector: 'app-slide-toggle',
  template: `
    <div  class="editor-full-width">
      <mat-slide-toggle [formControl]="control"  (click)="toggle(control.value)" color="primary">{{ controlConfig.label }}</mat-slide-toggle>
    </div>
  `,
  styles: [
    `
      :host,
      .editor-full-width {
        display: grid;  font-size: 12px;
      }
    `
  ]
})
export class SlideToggleComponent implements OnInit {
  @Input() controlConfig: FormConfig;
  @Input() control: FormControl;
  constructor() {}
  ngOnInit() {}
  toggle(value) {
    this.control.setValue(!value)
  }
}

