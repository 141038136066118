import { Component, ViewChild, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { Observable, timer } from 'rxjs';
import { tap, take } from 'rxjs/operators';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

export interface AppDataFieldConfig {
  kind: 'control' | 'column';
  headerKind?: 'control' | 'column' | 'label' | 'none';
  template?: 'icon' | 'stripe' | 'currency' | 'integer' | 'decimal' | 'date' | 'text' | 'count' | 'csv' | 'length' | 'template' | 'timestamp';
  templateFn?: any;
  label?: string;
  sticky?: boolean;
  flex?: string;
  style?: any;
}
export interface AppDataGridConfig {
  [field: string]: AppDataFieldConfig;
}
export interface AppDataGridEvent {
  control: string;
  config: AppDataFieldConfig;
  record: any;
}
@Component({
  selector: 'app-data-grid',
  templateUrl: './data-grid.component.html',
  styleUrls: ['./data-grid.component.scss']
})
export class DataGridComponent implements AfterViewInit {
  initialized = false;
  private DataGridConfiguration: AppDataGridConfig;
  @Input() canAdd = false;
  @Input() trackByKey = 'id';
  @Input() set dataGridConfig(config: AppDataGridConfig) {
    this.DataGridConfiguration = config;
    this.filtersCriteria = Object.entries(this.DataGridConfiguration).reduce(
      (acc, [k, v]) => (v.label !== '' ? { ...acc, [k]: '' } : acc),
      {}
    );
  }
  get dataGridConfig() {
    return this.DataGridConfiguration;
  }
  @Input() dataSource: Observable<any[]>;
  @Output() activated = new EventEmitter<AppDataGridEvent>();
  @Output() addAction = new EventEmitter<boolean>();
  filtersAndOr = 'AND';
  filtersCriteria;
  private tableData: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  get dataGridColumnKeys() {
    return Object.keys(this.dataGridConfig);
  }
  get dataGridColumnKeysWithControls() {
    return ['filter', ...this.dataGridColumnKeys];
  }
  constructor() {}
  ngAfterViewInit() {
    timer(1000)
      .pipe(
        take(1),
        tap(x => this.startUp())
      )
      .subscribe();
  }
  startUp() {
    this.dataSource
      .pipe(
        tap((collection: any[]) => {
          if (collection && collection.length && collection.length > 0) {
            this.tableData = new MatTableDataSource(
              collection.reduce(
                (acc, r) =>
                  r && r.id
                    ? [...acc, r]
                    : r && this.trackByKey !== 'id' && r[this.trackByKey]
                    ? [...acc, { ...r, id: r[this.trackByKey] }]
                    : acc,
                []
              )
            );
            this.tableData.sort = this.sort;
            this.tableData.paginator = this.paginator;
            this.tableData.filterPredicate = (data: any, filter: string): boolean => {
              if (filter === 'ALL') {
                return true;
              }
              const filters = JSON.parse(filter);
              const keys = Object.keys(filters);
              const matches = keys.reduce((acc, key) => {
                const criteria = filters[key];
                try {
                  const value = data[key]
                    ? data[key]
                        .toString()
                        .toLowerCase()
                        .trim()
                    : null;
                  const pass = data[key] ? (value.indexOf(criteria) > -1 ? 1 : 0) : 0;
                  // console.log(pass ? 'PASS' : 'FAIL', key, criteria, value);
                  return acc + pass;
                } catch {
                  // console.log('ERROR', key, criteria, data);
                  return -9999;
                }
              }, 0);
              return this.filtersAndOr === 'AND' ? (matches === keys.length ? true : false) : matches > 0 ? true : false;
            };
            this.initialized = true;
          } else {
            this.initialized = false;
            this.tableData = null;
          }
        })
      )
      .subscribe();
  }
  get Data() {
    return this.tableData;
  }
  getHeaderKind(column: string) {
    return this.dataGridConfig[column] && this.dataGridConfig[column].headerKind
      ? this.dataGridConfig[column].headerKind
      : this.getCellKind(column);
  }
  getCellKind(column: string) {
    return this.dataGridConfig[column] && this.dataGridConfig[column].kind ? this.dataGridConfig[column].kind : 'column';
  }
  getStyle(column: string) {
    return this.dataGridConfig[column] && this.dataGridConfig[column].style ? this.dataGridConfig[column].style : {};
  }
  getTemplate(column: string) {
    return this.dataGridConfig[column] && this.dataGridConfig[column].template ? this.dataGridConfig[column].template : 'text';
  }
  templateFn(column: string, record: any) {
    return this.dataGridConfig[column] && this.dataGridConfig[column].templateFn ? this.dataGridConfig[column].templateFn(record) : '';
  }
  getLabel(column: string) {
    return this.dataGridConfig[column] && this.dataGridConfig[column].label ? this.dataGridConfig[column].label : '';
  }
  getSticky(column: string) {
    return this.dataGridConfig[column] && this.dataGridConfig[column].sticky ? this.dataGridConfig[column].sticky : false;
  }
  getFlex(column: string) {
    return this.dataGridConfig[column] && this.dataGridConfig[column].flex ? this.dataGridConfig[column].flex : '1 1 0';
  }
  getFilter(column: string) {
    return this.dataGridConfig[column] && Object.keys(this.filtersCriteria).includes(column) ? this.filtersCriteria[column] : null;
  }
  setFilter(column: string, value: string) {
    if (
      typeof column === 'string' &&
      Object.keys(this.filtersCriteria).includes(column) &&
      value.toLowerCase() !== this.filtersCriteria[column].toLowerCase()
    ) {
      this.filtersCriteria = { ...this.filtersCriteria, [column]: value };
      const noBlanksCriteria = Object.entries(this.filtersCriteria).reduce(
        (acc, [k, v]) => (v && typeof v === 'string' && v.trim() !== '' ? { ...acc, [k]: v.trim().toLowerCase() } : acc),
        {}
      );

      const filterValue = noBlanksCriteria && Object.keys(noBlanksCriteria).length > 0 ? JSON.stringify(noBlanksCriteria) : 'ALL';
      if (filterValue) {
        this.tableData.filter = filterValue;
      }
    }
  }
  toggleANDOR() {
    this.filtersAndOr = this.filtersAndOr === 'AND' ? 'OR' : 'AND';
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.tableData.filter = filterValue;
  }
  controlClick(column: string, record: any): void {
    if (this.DataGridConfiguration[column] && this.DataGridConfiguration[column].kind === 'control') {
      this.activated.emit({ control: column, config: this.DataGridConfiguration[column], record });
    }
    // this.selected.next(JSON.parse(JSON.stringify(selected)));
    // const dialogRef = this.dialog.open(MaintainRestaurantComponent, {
    //   data: data
    // });
  }
  clickAdd() {
    this.addAction.emit(true);
  }
  trackById(index: any, item: { id: any }) {
    return item && item.id ? item.id : Math.random() * 1000;
  }
}
