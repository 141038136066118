<!-- <mat-form-field appearance="outline" floatLabel="always">
  <mat-label>{{ selectorConfig?.label }}</mat-label>
  <input matInput [matAutocomplete]="auto" [formControl]="control" type="text" (optionSelected)="optionSelected($event)" />
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
      {{ option ? displayFn(option) : '' }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field> -->
<div class="wrapper" *ngIf="dataSource$">
  <div class="content">
    <div class="selector-list-container">
      <app-data-grid
        [trackByKey]="idKey"
        [dataSource]="dataSource$"
        [dataGridConfig]="gridConfig"
        (activated)="select($event)"
        [canAdd]="false"
      ></app-data-grid>
    </div>
  </div>
</div>
