import { StripNumbersPipe } from './strip.pipe';
import { ToDatePipe } from './toDate.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StripePipe } from './stripe.pipe';
import { FileSizePipe } from './file-size.pipe';
import { PluckPipe } from './pluck.pipe';
import { SafeCSSPipe, SafeHTMLPipe } from './safecss.pipe';
import { SafeSourceURLPipe } from './safesource.pipe';
import { SafeURLPipe } from './safeurl.pipe';
import { SplitPipe } from './spilt.pipe';
import { DocPipe } from './doc.pipe';
import { DocPathPipe } from './doc-path.pipe';
import { LocationTextPipe } from './location-text.pipe';
import { TimeAgoPipe } from './time-ago.pipe';


const PIPES = [
  StripePipe,
  FileSizePipe,
  PluckPipe,
  SafeCSSPipe,
  SafeHTMLPipe,
  SafeSourceURLPipe,
  SafeURLPipe,
  SplitPipe,
  ToDatePipe,
  DocPipe,
  DocPathPipe,LocationTextPipe,
  StripNumbersPipe,
  TimeAgoPipe
];


@NgModule({
  imports: [CommonModule],
  declarations: PIPES,
  exports: PIPES
})
export class PipesModule { }
