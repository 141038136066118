import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DataGridViewComponent } from './data-grid-view/data-grid-view.component';


const routes: Routes = [
  {
    path: 'restaurants',
    component: DataGridViewComponent,
    data: {

    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ControllerRoutingModule { }
