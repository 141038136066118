import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { FormConfig } from '../editor.interface';
@Component({
  selector: 'app-input',
  template: `
    <mat-form-field class="editor-full-width" appearance="standard" floatLabel="always">
      <mat-label style="color:#0064AF">{{controlConfig?.label}}</mat-label>
      <input matInput [formControl]="control" [type]="controlConfig?.inputType" />
      <mat-hint>{{controlConfig?.hint}}</mat-hint>
      <ng-container *ngFor="let validation of controlConfig?.validations" ngProjectAs="mat-error">
        <mat-error *ngIf="control?.hasError(validation?.name)">{{ validation?.message }}</mat-error>
      </ng-container>
    </mat-form-field>
  `,
  styles: [
    `
      :host,
      .editor-full-width {
        display: grid;
        font-size: 12px;
        max-height:40px;
        place-content:  stretch;
      }
    `
  ]
})
export class InputComponent implements OnInit {
  @Input() controlConfig: FormConfig;
  @Input() control: FormControl;

  constructor() {}
  ngOnInit() {}
}
