import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'split'
})
export class SplitPipe implements PipeTransform {
  transform(value: any, splitter: string): any {
    return value && typeof value === 'string' ? value.split(splitter) : value;
  }
}
